import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

const LanguagesTab = ({ languagesData, state, onChange }) => {
  const language = (e) => {
    if (e === "en") {
      return "English";
    } else {
      return "French";
    }
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          fontWeight: "bold",
          borderBottom: "1px solid black",
          borderRight: "1px solid black",
          textAlign: "center",
        }}
      >
        <Grid width={"20%"} sx={{ borderRight: "1px solid black" }}>
          Languages
        </Grid>
        <Grid width={"40%"} sx={{ borderRight: "1px solid black" }}>
          Title
        </Grid>
        <Grid width={"40%"}>Description</Grid>
      </Grid>
      {languagesData?.length > 0 ? (
        languagesData.map((obj, i) => (
          <Grid
            container
            key={i}
            sx={{
              textAlign: "center",
              marginBottom: "4%",
            }}
          >
            <Grid width={"20%"}>{language(obj.lang)}</Grid>
            <Grid width={"40%"}>
              <input
                id="title"
                name="title"
                value={obj.title || ""}
                onChange={(e) => onChange(e, obj.lang)}
              />
            </Grid>
            <Grid width={"40%"}>
              <input
                id="desc"
                name="desc"
                value={obj.desc || ""}
                onChange={(e) => onChange(e, obj.lang)}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid>
          <Grid
            container
            sx={{
              textAlign: "center",
              marginBottom: "4%",
              marginTop: "4%",
            }}
          >
            <Grid width={"20%"}>{language("en")}</Grid>
            <Grid width={"40%"}>
              <input
                id="pageTitle"
                name="pageTitle"
                value={state.pageTitle || ""}
                onChange={onChange}
              />
            </Grid>
            <Grid width={"40%"}>
              <input
                id="pageDesc"
                name="pageDesc"
                value={state.pageDesc || ""}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              textAlign: "center",
              marginBottom: "4%",
              marginTop: "4%",
            }}
          >
            <Grid width={"20%"}>{language("fr")}</Grid>
            <Grid width={"40%"}>
              <input
                id="pageFrTitle"
                name="pageFrTitle"
                value={state.pageFrTitle || ""}
                onChange={onChange}
              />
            </Grid>
            <Grid width={"40%"}>
              <input
                id="pageFrDesc"
                name="pageFrDesc"
                value={state.pageFrDesc || ""}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default LanguagesTab;
