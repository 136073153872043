// Importing necessary modules from Redux Toolkit and universal-cookie
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the authentication slice
const userFeaturesSliceInitialState = {
  // Retrieve userAll from cookies if available, otherwise initialize as an empty object
  menuPagesFilter: {
    language: "EN",
    target: "user",
    platform: "mob,web",
    searchTxt: "",
  },
  userMenuFilter: {
    searchTxt: "",
  },
  menuPagesData: [],
  userMenuData: [],
  updatePageData: {},
  pagesDataChanges: {},
};

// Create a slice for authentication using Redux Toolkit's createSlice function
const userFeaturesSlice = createSlice({
  name: "userFeatures", // Name of the slice
  initialState: userFeaturesSliceInitialState, // Initial state defined above

  // Define the reducers (functions to update the state)
  reducers: {
    // Set user credentials in the state and cookies
    setMenuPagesFilter: (state, action) => {
      // Update the state with the new user information
      state.menuPagesFilter = action.payload;
    }, // Set user credentials in the state and cookies
    setUserMenuFilter: (state, action) => {
      // Update the state with the new user information
      state.userMenuFilter = action.payload;
    },
    setMenuPagesData: (state, action) => {
      // Update the state with the new user information
      state.menuPagesData = action.payload;
    },
    setUserMenuData: (state, action) => {
      // Update the state with the new user information
      state.userMenuData = action.payload;
    },
    setUpdatePageData: (state, action) => {
      // Update the state with the new user information
      state.updatePageData = action.payload;
    },
    setPagesDataChanges: (state, action) => {
      // Update the state with the new user information
      state.pagesDataChanges = action.payload;
    },
  },
});

// Export the actions to be used in other parts of the application
export const {
  setMenuPagesFilter,
  setUserMenuFilter,
  setMenuPagesData,
  setUserMenuData,
  setUpdatePageData,
  setPagesDataChanges,
} = userFeaturesSlice.actions;

// Export the reducer to be used in the store configuration
export default userFeaturesSlice.reducer;
