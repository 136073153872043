import "./index.css";
import * as React from "react";
import { ListBoxComponent } from "@syncfusion/ej2-react-dropdowns";

// import data from "./dataSource.json"; // Use default import

const DualListBox = ({ data, titles, onChanges }) => {
  const dataA = data.groupa; // Access properties from the default import
  const dataB = data.groupb;
  const fields = {
    text: "text",
    description: "description",
    icon: "icon",
  };
  const toolbarSettingsA = {
    items: ["moveTo", "moveFrom", "moveAllTo", "moveAllFrom"],
  };
  const toolbarSettingsB = {
    items: ["moveUp", "moveDown"],
  };
  const noRecordsTemplate =
    '<div class="e-list-nrt"><span>NO DATA AVAILABLE</span></div>';

  const handleGroupBChange = (e) => {
    onChanges(e);
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="dual-list-wrapper">
          <div className="dual-list-groupa">
            <h4>{titles[0]}</h4>
            <ListBoxComponent
              dataSource={dataA}
              fields={fields}
              height="330px"
              scope="#combined-listbox"
              toolbarSettings={toolbarSettingsA}
              noRecordsTemplate={noRecordsTemplate}
              itemTemplate='<div class="list-wrapper"><span style="background-image: url(${icon});width: 50px; height: 50px;" class="image e-avatar e-avatar-xlarge e-avatar-circle"></span><span class="text">${text}</span><span class="description">${description}</span></div>'
              actionComplete={handleGroupBChange}
            />
          </div>
          <div className="dual-list-groupb">
            <h4>{titles[1]}</h4>
            <ListBoxComponent
              id="combined-listbox"
              dataSource={dataB}
              height="330px"
              fields={fields}
              noRecordsTemplate={noRecordsTemplate}
              // toolbarSettings={toolbarSettingsB}
              itemTemplate='<div class="list-wrapper"><span style="background-image: url(${icon}); width: 50px; height: 50px;" class="image e-avatar e-avatar-xlarge e-avatar-circle"></span><span class="text">${text}</span><span class="description">${description}</span></div>'
              actionComplete={handleGroupBChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DualListBox;
