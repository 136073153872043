import React, { useState } from "react";

const CutomizedAddTemplate = (props) => {
  const [state, setState] = useState({ ...props[0] });

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // props?.setUpdatedChanges((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  return (
    <div id="tab0" className="tab" style={{ display: "ruby" }}>
      <div className="form-row" style={{ width: "50%" }}>
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <input
              id="menuDesc"
              name="menuDesc"
              type="text"
              value={state.menuDesc || ""}
              onChange={onChange}
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Description</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CutomizedAddTemplate;
