// Define an array of target options for dropdowns or filters
const targets = [
  {
    title: "User", // Display label
    value: "user", // Internal value for the "User" option
  },
  {
    title: "Admin", // Display label
    value: "admin", // Internal value for the "Admin" option
  },
];

// Define an array of platform options for dropdowns or filters
const platforms = [
  {
    title: "All", // Display label
    value: "mob,web", // Internal value representing both mobile and web platforms
  },
  {
    title: "Web", // Display label
    value: "web", // Internal value for web-only platform
  },
  {
    title: "Mobile", // Display label
    value: "mob", // Internal value for mobile-only platform
  },
];

// Define an array of type options for dropdowns or filters
const type = [
  {
    title: "Solo", // Display label
    value: "solo", // Internal value for "Solo" type
  },
  {
    title: "Composite", // Display label
    value: "composite", // Internal value for "Composite" type
  },
];

// Define an array of language options for dropdowns or filters
const language = [
  {
    title: "English", // Display label
    value: "EN", // Internal value for English language
  },
  {
    title: "French", // Display label
    value: "FR", // Internal value for French language
  },
];

// Combine all arrays into a single object for easier imports and access
const JsonArrays = { platforms, targets, type, language };

// Export the JsonArrays object as the default export
export default JsonArrays;
