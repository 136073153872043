import React, { useState, useRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  DetailRow,
  Page,
  Sort,
  Filter,
  Toolbar,
  Edit,
  Selection,
  PdfExport,
  ExcelExport,
  ColumnMenu,
  ContextMenu,
  Resize,
} from "@syncfusion/ej2-react-grids";
import "./singleGrid.css";
import "font-awesome/css/font-awesome.min.css";

function SingleHierarchy({
  columns,
  data,
  primaryKey,
  selection,
  onChanges,
  contextMenu,
  readOnly,
  setRowSelected,
  contextMenuItems,
  contextMenuClick,
  onDelete,
  handleClose,
  required,
  mode,
  template,
  style,
  someChanges,
}) {
  const [gridData, setGridData] = useState([...data]);
  const gridInstance = useRef(null);

  const filterSettings = { type: "Excel" };

  const editDialogTemplate = null; // Use default template for edit

  const toolbarOptions = (readOnly && []) ||
    (mode === "noPDF" && [
      { id: "Add", tooltipText: "Add", prefixIcon: "e-add" },
      { id: "Edit", tooltipText: "Edit", prefixIcon: "e-edit" },
      { id: "Delete", tooltipText: "Delete", prefixIcon: "e-delete" },
      {
        id: "ExcelExport",
        tooltipText: "Excel Export",
        prefixIcon: "e-excelexport",
      },
    ]) || [
      { id: "Add", tooltipText: "Add", prefixIcon: "e-add" },
      { id: "Edit", tooltipText: "Edit", prefixIcon: "e-edit" },
      { id: "Delete", tooltipText: "Delete", prefixIcon: "e-delete" },
      {
        id: "PdfExport",
        tooltipText: "PDF Export",
        prefixIcon: "e-pdfexport",
      },
      {
        id: "ExcelExport",
        tooltipText: "Excel Export",
        prefixIcon: "e-excelexport",
      },
    ];

  const editSettings = {
    allowDeleting: !readOnly,
    allowEditing: !readOnly,
    allowAdding: !readOnly,
    mode: "Dialog",
    template: template && ((props) => template?.(props, gridInstance)),
  };

  const pageSettings = { pageCount: 5 };

  const selectionSettings = {
    type: selection,
    checkboxMode: "ResetOnRowClick",
    persistSelection: true,
  };

  const actionBegin = (args) => {
    if (args.requestType === "delete") {
      const shouldCancel = window.confirm(
        "Are you sure you want to delete this row?"
      );
      if (!shouldCancel) {
        args.cancel = true;
        return;
      }

      const updatedData = gridData.filter(
        (row) => !args.data.some((item) => item[primaryKey] === row[primaryKey])
      );
      setGridData(updatedData);
      onDelete?.();
      handleClose?.();
    }

    if (required && args.requestType === "save") {
      const add = args?.requestType === "add";
      if (
        !someChanges.pageTarget ||
        !someChanges.platform ||
        !someChanges.pageType ||
        !someChanges.pageTitle ||
        (add && !someChanges.pageFrTitle) ||
        !someChanges.pageDesc ||
        (add && !someChanges.pageFrDesc)
      ) {
        alert("Some fields are required to proceed.");
        args.cancel = true;
      }
    }
  };

  const actionComplete = (args) => {
    onChanges?.(args, gridInstance);

    if (
      style &&
      (args.requestType === "add" || args.requestType === "beginEdit")
    ) {
      const dialog = args.dialog;
      dialog.height = "auto";
      dialog.width = "30%";
    }

    if (style === "usermenu" && args.requestType === "beginEdit") {
      const dialog = args.dialog;
      dialog.height = "auto";
      dialog.width = "50%";
    }

    if (args.requestType === "save" || args.requestType === "delete") {
      gridInstance.current?.refresh();
    }
  };

  const contextMenuClicking = (args) => {
    contextMenuClick?.(args, gridInstance);
  };

  const rowSelected = (args) => {
    setRowSelected?.(args?.data);
  };

  const toolbarClick = (args) => {
    if (!gridInstance.current) return;

    switch (args.item.id) {
      case "Add":
        gridInstance.current.addRecord();
        break;
      case "Edit":
        if (gridInstance.current.getSelectedRecords().length > 0) {
          gridInstance.current.startEdit();
        } else {
          alert("Please select a row to edit.");
        }
        break;
      case "Delete":
        if (gridInstance.current.getSelectedRecords().length > 0) {
          gridInstance.current.deleteRecord();
        } else {
          alert("Please select a row to delete.");
        }
        break;
      case "ExcelExport":
        gridInstance.current.excelExport({ hierarchyExportMode: "All" });
        break;
      case "PdfExport":
        gridInstance.current.pdfExport({ hierarchyExportMode: "All" });
        break;
      default:
        break;
    }
  };

  return (
    <div className="control-pane" style={{ position: "relative" }}>
      <div className="control-section">
        <GridComponent
          dataSource={gridData}
          id="MasterDetailsExport"
          ref={gridInstance}
          allowSorting={true}
          showColumnMenu={true}
          allowFiltering={true}
          filterSettings={filterSettings}
          toolbar={!readOnly ? toolbarOptions : []}
          allowPaging={true}
          editSettings={editSettings}
          pageSettings={pageSettings}
          selectionSettings={selectionSettings}
          allowPdfExport={!readOnly}
          allowExcelExport={!readOnly}
          actionBegin={actionBegin}
          actionComplete={actionComplete}
          contextMenuItems={contextMenu && contextMenuItems}
          contextMenuClick={contextMenu && contextMenuClicking}
          rowSelected={rowSelected}
          toolbarClick={toolbarClick}
        >
          <ColumnsDirective>
            {selection !== "Single" && (
              <ColumnDirective type="checkbox" width="50" />
            )}
            {columns?.map((cd, i) => (
              <ColumnDirective
                key={i}
                field={cd?.field}
                headerText={cd?.headerText}
                width={cd?.width || "100"}
                isPrimaryKey={cd?.isPrimaryKey}
                allowEditing={cd?.allowEditing ?? !cd?.isPrimaryKey}
                editType={cd?.editType || "text"}
                editTemplate={cd?.editTemplate}
                visible={cd?.visible && cd?.visible}
              />
            ))}
          </ColumnsDirective>
          {!readOnly && (
            <Inject
              services={[
                DetailRow,
                Page,
                Sort,
                Filter,
                Toolbar,
                Edit,
                Selection,
                PdfExport,
                ExcelExport,
                ColumnMenu,
                ContextMenu,
                Resize,
              ]}
            />
          )}
        </GridComponent>
      </div>
    </div>
  );
}

export default SingleHierarchy;
