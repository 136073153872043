import React from "react"; // Importing React library
import "./myAlertDialog.css"; // Importing CSS styles for the alert dialog

// MyAlertDialog is a functional component that displays a customizable alert dialog
const MyAlertDialog = ({
  open, // Boolean to control the visibility of the dialog
  handleClose, // Function to handle the closing of the dialog
  title, // Title text to display at the top of the dialog
  content, // Main content text to display in the dialog
  buttonTitle, // Text for the primary action button
  buttonClick, // Function to handle click events for the primary action button
}) => {
  return (
    <div>
      {/* Render dialog only if 'open' is true */}
      {open && (
        <>
          {/* Overlay to dim the background and close dialog when clicked */}
          <div className="overlay" onClick={handleClose}></div>

          {/* Main container for the dialog */}
          <div className="container">
            {/* Title section */}
            <div
              style={{
                textAlign: "center",
                color: "#064987", // Blue color for the title
                fontWeight: "bold", // Bold text for emphasis
                fontSize: "1.3em", // Slightly larger font size for the title
                marginBottom: "2%", // Spacing below the title
              }}
            >
              {title}
            </div>

            {/* Content section */}
            <div>{content}</div>

            {/* Footer section for buttons */}
            <div
              style={{
                display: "flex", // Flexbox for button alignment
                justifyContent: "flex-end", // Align buttons to the right
                marginTop: "3%", // Spacing above the buttons
              }}
            >
              {/* Primary action button */}
              <button className="buttonSave" onClick={buttonClick}>
                {buttonTitle}
              </button>

              {/* Close button */}
              <button onClick={handleClose} className="buttonClose">
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyAlertDialog; // Exporting the MyAlertDialog component for use in other parts of the application
