import { Box, FormLabel, Grid } from "@mui/material";
import React from "react";
import BasicTextFields from "../../../../../../../Common/TextField";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";

const UpdateApps = ({ updateApp, setUpdateApp }) => {
  const handleChange = (e, item) => {
    setUpdateApp({
      ...updateApp,
      [item]: e?.target?.value,
    });
  };

  const handleFileSelected = (event) => {
    const fileDetails = event.filesData[0]; // Get the first selected file
    if (fileDetails) {
      const file = fileDetails.rawFile;
      const fileUrl = URL.createObjectURL(file); // Generate a temporary URL for the file
      // console.log("Image URL:", fileUrl);
      setUpdateApp({
        ...updateApp,
        appImgURL: fileUrl,
      });
      // Use the fileUrl as needed (e.g., display it in an img tag or save it)
    }
  };

  return (
    <Box sx={{ marginY: "2%" }}>
      <Grid container>
        <Grid item width={"20%"}>
          <BasicTextFields
            value={updateApp?.appId ? updateApp?.appId : ""} // Setting the value from the filter state
            id="appId" // Assigning a unique ID
            label="ID"
            disabled
          />
        </Grid>
        <Grid item width={"3%"}></Grid>
        <Grid item width={"32%"}>
          <BasicTextFields
            // sx={() => TextFieldStyle(e, "sectionTitle")}
            value={updateApp?.appTitle ? updateApp?.appTitle : ""} // Setting the value from the filter state
            id="appTitle" // Assigning a unique ID
            onChange={(e) => handleChange(e, "appTitle")} // Handling change for address number
            label="Title"
          />
        </Grid>
        <Grid item width={"3%"}></Grid>
        <Grid item width={"42%"}>
          <BasicTextFields
            // sx={() => TextFieldStyle(e, "sectionTitle")}
            value={updateApp?.appDesc ? updateApp?.appDesc : ""} // Setting the value from the filter state
            id="appDesc" // Assigning a unique ID
            onChange={(e) => handleChange(e, "appDesc")} // Handling change for address number
            label="Description"
          />
        </Grid>{" "}
      </Grid>
      <Grid container sx={{ marginY: "2%" }}>
        <Grid item width={"30%"}>
          <BasicTextFields
            // sx={() => TextFieldStyle(e, "sectionTitle")}
            value={updateApp?.appRoute ? updateApp?.appRoute : ""} // Setting the value from the filter state
            id="appRoute" // Assigning a unique ID
            onChange={(e) => handleChange(e, "appRoute")} // Handling change for address number
            label="Route"
          />
        </Grid>
        <Grid item width={"5%"}></Grid>
        <Grid item width={"30%"}>
          <BasicTextFields
            // sx={() => TextFieldStyle(e, "sectionTitle")}
            value={updateApp?.appSocketLabel ? updateApp?.appSocketLabel : ""} // Setting the value from the filter state
            id="appSocketLabel" // Assigning a unique ID
            onChange={(e) => handleChange(e, "appSocketLabel")} // Handling change for address number
            label="Socket Label"
          />
        </Grid>{" "}
      </Grid>
      <Grid container>
        <Grid item width={"100%"}>
          <FormLabel htmlFor="image-uploader" style={{ marginBottom: "8px" }}>
            Image URL
          </FormLabel>
          <UploaderComponent
            id="image-uploader"
            allowedExtensions=".jpg,.jpeg,.png,.gif"
            multiple={false}
            selected={handleFileSelected} // Attach the event handler
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateApps;

{
  /* <BasicTextFields
  // sx={() => TextFieldStyle(e, "sectionTitle")}
  value={updateApp?.appImgURL ? updateApp?.appImgURL : ""} // Setting the value from the filter state
  id="appImgURL" // Assigning a unique ID
  onChange={(e) => handleChange(e, "appImgURL")} // Handling change for address number
  label="Image URL"
/> */
}

// const [imageUrl, setImageUrl] = React.useState(null);

// const handleFileSelected = (event) => {
//   const fileDetails = event.filesData[0];
//   if (fileDetails) {
//     const fileUrl = URL.createObjectURL(fileDetails.rawFile);
//     setImageUrl(fileUrl);
//   }
// };

// // Later in the render:
// {imageUrl && <img src={imageUrl} alt="Selected" style={{ maxWidth: '100%' }} />}
