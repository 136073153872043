// Import necessary components from MUI (Material-UI) and React
import { Box, Grid, Tooltip } from "@mui/material";
import React from "react";
import BigBoxItems from "./BigBoxItems";

// Define the DefaultComponent which receives props 'data' and 'path'
const DefaultComponent = ({ data }) => {
  return (
    <Box>
      {/* Check if the 'pageType' in 'data' is 'composite', then map over the 'pageSections' */}
      {data?.pageType === "composite" &&
        data?.pageSections?.map((e) => (
          // Create a box for each section with some margin and a unique key
          <Box marginY={"3%"} key={e?.sectionId}>
            {/* Render a Grid to display the section title with custom styles */}
            <Grid
              sx={{
                backgroundColor: "#175779", // Dark blue background
                color: "white", // White text color
                height: "30px", // Set height of the title bar
                borderRadius: "7.5px", // Rounded corners
                marginBottom: "2%", // Space below the title
                paddingLeft: "3%", // Left padding
                display: "flex", // Flex display to center items
                alignItems: "center", // Vertically center content
                fontSize: "1.4em", // Font size for the section title
              }}
            >
              {/* Display the section title */}
              {e?.sectionTitle}
            </Grid>

            {/* Render a grid to display apps in the section */}
            <Grid
              sx={{
                display: "flex", // Flexbox for arranging the items
                justifyContent: "left", // Align items to the left
                width: "100%", // Full width of the container
                flexWrap: "wrap", // Wrap items to the next line if needed
              }}
            >
              {/* Map through each app (lBox) in the section */}
              {e?.sectionApps?.map((lBox, index) => (
                // Tooltip to display additional info when hovering over an app
                <Tooltip
                  key={lBox?.appId} // Unique key for each app
                  slotProps={{
                    tooltip: {
                      sx: {
                        width: "11rem", // Set width of the tooltip
                        fontSize: "0.9rem", // Font size of tooltip text
                      },
                    },
                  }}
                  // Tooltip content displaying app details like Page ID and Name
                  title={
                    <Grid container>
                      <Grid container>
                        <Grid item xs={5}>
                          Page ID:
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={6}>
                          {lBox?.appId}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          Name:
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={6}>
                          {lBox?.appRoute}
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  placement="bottom" // Position the tooltip below the app item
                >
                  {/* Box for each app item with specified width and margin */}
                  <Box sx={{ width: "20%", marginRight: "5%" }}>
                    {/* Render the BigBoxItems component for each app */}
                    <BigBoxItems lBox={lBox} />
                  </Box>
                </Tooltip>
              ))}
            </Grid>
          </Box>
        ))}
    </Box>
  );
};

export default DefaultComponent;
