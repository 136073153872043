import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid";
import CutomizedEditTemplate from "./CustomizedEditTemplate";
import CutomizedAddTemplate from "./CustomizedAddTemplate";
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer";

const UserMenuBody = ({ onSearch }) => {
  const socketadmin = useSelector((state) => state.auth.socketadmin);
  const userMenuData = useSelector(
    (state) => state?.userFeatures?.userMenuData
  )?.menus;
  const dispatch = useDispatch();
  const [updateChanges, setUpdateChanges] = useState([]);

  const ColumnDirective = [
    {
      field: "menuId",
      headerText: "Menu ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
      allowEditing: false,
    },
    { field: "menuDesc", headerText: "Description", width: "100" },
    { field: "userNbr", headerText: "Users Count", width: "100" },
  ];

  // Function to send data to the server via socket connection
  const sendData = (data, action) => {
    const allPages = userMenuData?.filter(
      (item) => item?.menuId === data?.menuId
    )?.[0]?.pages;
    const changes = updateChanges;

    const updatedPages = [
      // Step 1: Process `allPages`
      ...allPages.map((page) => {
        const existsInChanges = changes.some(
          (change) => change.pageId === page.pageId // Assume `id` is the unique identifier
        );
        return {
          ...page,
          action: existsInChanges ? "none" : "remove",
        };
      }),
      // Step 2: Process `changes` not in `allPages`
      ...changes
        .filter(
          (change) => !allPages.some((page) => page.pageId === change.pageId) // Objects in `changes` but not in `allPages`
        )
        .map((change) => ({ ...change, action: "add" })),
    ];

    const sendedData =
      (action === "add" && {
        action: "add",
        desc: data?.menuDesc ? data?.menuDesc : "",
      }) ||
      (action === "update" && {
        action: "edit",
        menuId: data?.menuId,
        desc: data?.menuDesc ? data?.menuDesc : "",
        menuPages: updatedPages?.map((obj) => {
          return {
            action: obj?.action,
            pageId: obj?.pageId,
          };
        }),
      }) ||
      (action === "delete" && {
        action: "delete",
        menuId: data?.menuId,
      });

    socketadmin.emit(
      "automation:web:admin:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "menuUpdate", // Specifying the search type
          menuUpdate: sendedData,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true, // Open the notification snackbar
            message: response?.message?.text, // Display the response message
            type: response?.message?.messageType, // Set notification type
          })
        );
        onSearch();
      }
    );
  };

  // Function to handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      sendData(args?.promise?.[0], "delete");
    } else if (args.action === "edit") {
      const updatedData = { ...args.data }; // Clone the object
      delete updatedData.sections; // Exclude read-only field
      sendData(updatedData, "update");
    } else if (args.action === "add") {
      sendData(args.data, "add");
    }
  };

  const dialogTemplate = (props, grid) => {
    const a = [props, grid];

    if (!props?.isAdd) {
      return (
        <CutomizedEditTemplate
          {...a}
          setUpdateChanges={setUpdateChanges}
          updateChanges={updateChanges}
        />
      );
    } else {
      return <CutomizedAddTemplate {...a} />;
    }
  };

  return (
    <Grid>
      <SingleHierarchy
        columns={ColumnDirective}
        data={JSON.parse(JSON.stringify(userMenuData || []))}
        primaryKey={"menuId"}
        selection={"Single"}
        onChanges={onChanges}
        readOnly={false}
        template={(props, grid) => dialogTemplate(props, grid)}
        style={"usermenu"}
      />
    </Grid>
  );
};

export default UserMenuBody;
