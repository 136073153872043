import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import SingleHierarchy from "../../../../../Common/DataGrid/singleGrid";
import { useSelector } from "react-redux";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";

const ContextMenuComponent = ({
  menu,
  changes,
  setChanges,
  data,
  isContextMenuLoading,
  phoneTypes,
}) => {
  const contextMenuData = useSelector(
    (state) => state?.addressBook?.contextMenuData
  );

  const editPhoneTypeParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input"; // Input styling
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Read selected value
    write: (args) => {
      new DropDownList({
        dataSource: phoneTypes || [], // Default to an empty array if undefined
        fields: { text: "phoneType", value: "phoneType" }, // Map 'phoneType' as both text and value
        value: args.rowData[args.column.field] || null, // Preselect the current value
        popupHeight: "300px",
        placeholder: "Select Unit",
        floatLabelType: "Always",
      }).appendTo(args.element); // Attach the dropdown to the editor element
    },
  };

  const ColumnPhoneDirective = [
    {
      field: "lineId",
      headerText: "ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
    },
    { field: "countryCode", headerText: "Code", width: "100" },
    { field: "phoneNb", headerText: "Number", width: "100" },
    {
      field: "phoneType",
      headerText: "Type",
      width: "100",
      editType: "dropdownedit", // Specify the editor type as dropdown
      edit: editPhoneTypeParams, // Attach the dropdown editor configuration
    },
  ];

  const ColumnEmailsDirective = [
    {
      field: "lineId",
      headerText: "ID",
      width: "50",
      isPrimaryKey: true,
      isIdentity: true,
    },
    { field: "email", headerText: "Email", width: "300" },
  ];

  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  function getRandomlineId() {
    const min = 100;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const onChanges = (args, gridInstance) => {
    let updated = {
      action: args?.action === "add" ? "add" : "update",
      addressNb: data?.addressNb,
      ...args.data,
    };

    if (args.requestType === "delete") {
      const changesDelete = {
        action: "delete",
        lineId: args?.promise?.[0]?.lineId,
        addressNb: data?.addressNb,
      };

      const existingIndex = changes?.findIndex(
        (change) => change?.lineId === args?.promise?.[0]?.lineId
      );

      if (gridInstance?.current) {
        gridInstance.current.refresh();
      }

      if (existingIndex !== -1) {
        setChanges((prevChanges) => {
          if (changes[existingIndex]?.action === "update") {
            const updatedChanges = prevChanges.filter(
              (_, index) => index !== existingIndex
            );
            return [...updatedChanges, changesDelete];
          } else {
            const updatedChanges = prevChanges.filter(
              (_, index) => index !== existingIndex
            );
            return [...updatedChanges];
          }
        });
      } else {
        setChanges((prevChanges) => [...prevChanges, changesDelete]);
      }
    } else if (args.action === "edit") {
      const existingIndex = changes?.findIndex(
        (change) => change?.lineId === args?.data?.lineId
      );

      if (existingIndex !== -1) {
        if (contextMenuData?.some((item) => areObjectsEqual(item, args.data))) {
          setChanges((prevChanges) => {
            const updatedChanges = [...prevChanges];
            updatedChanges.splice(existingIndex, 1);
            return updatedChanges;
          });
        } else if (changes[existingIndex]?.action === "add") {
          setChanges((prevChanges) =>
            prevChanges.map((change, index) =>
              index === existingIndex
                ? {
                    action: "add",
                    addressNb: data?.addressNb,
                    ...{ ...args.data },
                  }
                : change
            )
          );
        } else {
          setChanges((prevChanges) =>
            prevChanges.map((change, index) =>
              index === existingIndex
                ? {
                    ...change,
                    addressNb: data?.addressNb,
                    ...{ ...args.data },
                  }
                : change
            )
          );
        }
      } else {
        setChanges((prevChanges) => [...prevChanges, { ...updated }]);
      }
    } else if (args.action === "add") {
      if (!args.data.lineId) {
        args.data.lineId = getRandomlineId();
      }

      updated = {
        action: "add",
        addressNb: data?.addressNb,
        ...args.data,
      };

      setChanges((prevChanges) => [...prevChanges, updated]);
    }

    if (args.requestType === "save" || args.requestType === "delete") {
      if (gridInstance?.current) {
        gridInstance.current.refresh();
      }
    }
  };

  const openContextMenuPopUp = (type, rowData) => {
    // Logic for opening context menu
  };

  const getContextMenuData = (type, id) => {
    // Logic for retrieving context menu data
  };

  return (
    <Box>
      <Grid>
        {!isContextMenuLoading ? (
          <SingleHierarchy
            columns={
              menu === "emails" ? ColumnEmailsDirective : ColumnPhoneDirective
            }
            data={JSON.parse(JSON.stringify(contextMenuData))}
            primaryKey={"lineId"}
            selection={"Multiple"}
            onChanges={onChanges}
            openContextMenuPopUp={openContextMenuPopUp}
            getContextMenuData={getContextMenuData}
            contextMenu={false}
            readOnly={false}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography variant="body1">Loading records...</Typography>
            <CircularProgress size={24} sx={{ ml: 2 }} />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ContextMenuComponent;
