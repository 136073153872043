import { Box, CircularProgress, Grid } from "@mui/material"; // Importing Material-UI components for layout and loading indicator
import React, { useState } from "react"; // Importing React and useState hook
import { useDispatch, useSelector } from "react-redux"; // Redux hooks for state management
import MenuPagesFilterReturn from "./FilterReturn"; // Custom filter return component
import FilterComponent from "../../../Common/Filter"; // Common filter component
import MenuPagesBody from "./Body"; // Body component for menu pages
import { setMenuPagesData } from "../../../../Services/Redux/Reducers/userFeaturesReducer"; // Redux action for setting menu pages data
import { setNotificationSnackBar } from "../../../../Services/Redux/Reducers/authSliceReducer"; // Redux action for showing notifications

// Main component for managing and displaying menu pages
const MenuPagesComponent = () => {
  const socketadmin = useSelector((state) => state.auth.socketadmin); // Accessing socketadmin instance from Redux store
  const [isLoading, setIsLoading] = useState(false); // State to manage the loading spinner
  const menuPagesFilter = useSelector(
    (state) => state?.userFeatures?.menuPagesFilter // Accessing filter state from Redux store
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch

  const [openGraphicUpdate, setOpenGraphicUpdate] = useState({
    open: false, // Controls the visibility of graphic updates
    data: {}, // Stores data for the graphic update
  });

  // Function to handle search logic by sending a socket event
  const onSearch = () => {
    setIsLoading(true); // Show loading spinner while search is in progress
    socketadmin.emit(
      "automation:web:admin:usr:v1", // Socket event name
      {
        request: "userMenu", // API request type
        userMenu: {
          type: "pages", // Specify the type of data being fetched
          pages: {
            searchTxt: menuPagesFilter?.searchTxt, // Pass search text from filter
            language: menuPagesFilter?.language, // Pass selected language
            platform: menuPagesFilter?.platform, // Pass selected platform
            target: menuPagesFilter?.target, // Pass selected target
          },
        },
      },
      (response) => {
        console.log("response");
        console.log(response);
        if (response?.responseStatus === "success") {
          // If the server response is successful, update the Redux store
          dispatch(
            setMenuPagesData(
              response?.data?.map((obj) => {
                return {
                  ...obj, // Spread the original object
                  // Map and transform specific fields for better readability
                  language:
                    (obj?.language === "EN" && "English") ||
                    (obj?.language === "FR" && "French"),
                  pageTarget:
                    (obj?.pageTarget === "user" && "User") ||
                    (obj?.pageTarget === "admin" && "Admin"),
                  pageType:
                    (obj?.pageType === "solo" && "Solo") ||
                    (obj?.pageType === "composite" && "Composite"),
                  platform:
                    (obj?.platform === "mob,web" && "All") ||
                    (obj?.platform === "web" && "Web") ||
                    (obj?.platform === "mob" && "Mobile"),
                };
              })
            )
          );
        } else {
          // If the server response indicates failure, show a notification
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: response?.message?.text, // Display the response message
              type: response?.message?.messageType, // Set notification type
            })
          );
        }
        setIsLoading(false); // Hide loading spinner after search completes
      }
    );
  };

  return (
    <Box sx={{ color: "black" }}>
      {/* Main container with black text */}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        {/* Background color for the filter and body */}
        {!openGraphicUpdate?.open && (
          <FilterComponent
            children={<MenuPagesFilterReturn onSearch={onSearch} />}
          />
        )}
        {!isLoading && (
          <MenuPagesBody
            openGraphicUpdate={openGraphicUpdate} // Pass the open graphic update state
            setOpenGraphicUpdate={setOpenGraphicUpdate} // Setter for graphic update state
            onSearch={onSearch} // Trigger the search function
          />
        )}
      </Grid>
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width of the parent container
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Set spinner color to Material-UI primary blue
              width: "200px !important", // Override default width
              height: "200px !important", // Override default height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default MenuPagesComponent; // Exporting the component for reuse
