import React, { useState } from "react"; // Importing React and useState for managing component state
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid"; // Importing a custom SingleHierarchy DataGrid component
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import "../../../../Common/DataGrid/singleGrid.css"; // Importing custom CSS for the SingleHierarchy DataGrid
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer"; // Importing Redux action to set a notification
import MyAlertDialog from "../../../../Common/MyAlertDialog"; // Importing a custom alert dialog
import getUDCApi from "../../../../../Services/Common/UDCAPI";
import ContextMenuItemsComponent from "./ContextMenu";
import {
  setContextMenuItemsData,
  setFeatureTypeList,
  setUnitsList,
} from "../../../../../Services/Redux/Reducers/inventoryReducer";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";

const ItemsMasterBody = ({ brands }) => {
  const socketproject = useSelector((state) => state?.auth?.socketproject); // Accessing socketproject from the Redux store
  const itemsMasterData = useSelector(
    (state) => state?.inventory?.itemsMasterData // Accessing masterInfoData from the Redux store
  )?.map((data) => {
    // Mapping addressType values to readable strings (Employee or Customer)
    if (data?.addressType === "E") {
      return { ...data, addressType: "Employee" };
    } else {
      return { ...data, addressType: "Customer" };
    }
  });

  const [selectedRow, setSelectedRow] = useState([]);

  const brandList = useSelector(
    (state) => state?.inventory?.brandList // Selecting masterInfoData from Redux state
  )?.data;

  const unitsList = useSelector(
    (state) => state?.inventory?.unitsList // Selecting masterInfoData from Redux state
  );
  const featureTypeList = useSelector(
    (state) => state?.inventory?.featureTypeList // Selecting masterInfoData from Redux state
  );

  const units = unitsList?.data?.map((list) => {
    return {
      id: Math.random(),
      featureUnitDesc: list?.UVDESC,
    };
  });
  const featureType = featureTypeList?.data?.map((type) => {
    return {
      id: Math.random(),
      featureType: type?.UVDESC,
    };
  });

  const [isContextMenuLoading, setIsContextMenuLoading] = useState(false);

  const dispatch = useDispatch(); // Initializing Redux dispatch

  // State for managing changes in the data grid
  const [changes, setChanges] = useState([]);

  // State for controlling context menu visibility and data
  const [openContextMenu, setOpenContextMenu] = useState({
    menu: "",
    open: false,
    data: {},
  });

  // Function to open context menu with selected menu and data
  const openContextMenuPopUp = (menu, data) => {
    setOpenContextMenu({
      menu: menu,
      open: true,
      data: data,
    });
  };

  // Function to handle closing popups and resetting states
  const handleClose = () => {
    setChanges([]);
    dispatch(setContextMenuItemsData([])); // Clear context menu data in Redux store
    setOpenContextMenu({
      menu: "",
      open: false,
      data: {},
    });
  };

  const editBrandParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input"; // Input styling
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Read selected value
    write: (args) => {
      new DropDownList({
        dataSource: brands || [], // Default to an empty array if undefined
        fields: { text: "brandDesc", value: "brandDesc" }, // Map 'phoneType' as both text and value
        value: args.rowData[args.column.field] || null, // Preselect the current value
        popupHeight: "300px",
        placeholder: "Select Unit",
        floatLabelType: "Always",
      }).appendTo(args.element); // Attach the dropdown to the editor element
    },
  };

  // Column configuration for the DataGrid
  const ColumnDirective = [
    {
      field: "itemId", // Address number column
      headerText: "Item ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true, // Primary key, no editing allowed
    },
    { field: "itemNb", headerText: "Item Nb", width: "100" },
    { field: "itemDesc1", headerText: "Description 1", width: "100" },
    { field: "itemDesc2", headerText: "Description 2", width: "100" },
    {
      field: "brandDesc",
      headerText: "Brand",
      width: "100",
      editType: "dropdownedit",
      edit: editBrandParams,
    },
  ];

  // Function to send data to the server via socket connection
  const sendData = (data, action) => {
    const sendedData =
      (action === "add" && {
        action: "add",
        itemNb: data?.itemNb,
        itemDesc1: data?.itemDesc1,
        itemDesc2: data?.itemDesc2,
        brand: brandList?.filter(
          (brand) => brand?.UVDESC === data?.brandDesc
        )?.[0]?.UVVALUE,
        // brandDesc: data?.brandDesc,
      }) ||
      (action === "update" && {
        action: "update",
        itemId: data?.itemId,
        itemNb: data?.itemNb,
        itemDesc1: data?.itemDesc1,
        itemDesc2: data?.itemDesc2,
        brand: brandList?.filter(
          (brand) => brand?.UVDESC === data?.brandDesc
        )?.[0]?.UVVALUE,
        // brandDesc: data?.brandDesc,
      }) ||
      (action === "delete" && {
        action: "delete",
        itemId: data,
      });
    socketproject.emit(
      "automation:web:admin:usr:v1", // Emit data to server with socketproject connection
      {
        request: "inventory",
        inventory: {
          type: "items",
          items: sendedData,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true, // Display notification snackbar based on server response
            message: response?.message?.text,
            type: response?.message?.messageType,
          })
        );
      }
    );
  };

  // Function to fetch context menu data from the server
  const getContextMenuData = (menu, itemId) => {
    setIsContextMenuLoading(true);
    socketproject.emit(
      "automation:web:admin:usr:v1",
      {
        request: "inventory",
        inventory: {
          type: menu,
          [menu]: {
            action: "search",
            itemId: itemId,
          },
        },
      },
      (response) => {
        dispatch(setContextMenuItemsData(response?.data)); // Update context menu data in Redux store
        if (menu === "itemsFeature") {
          getUDCApi(
            socketproject,
            "IV",
            "UT",
            dispatch,
            setUnitsList,
            setIsContextMenuLoading
          );
          getUDCApi(
            socketproject,
            "IV",
            "TP",
            dispatch,
            setFeatureTypeList,
            setIsContextMenuLoading
          );
        } else {
          setIsContextMenuLoading(false);
        }
      }
    );
  };

  // Function to handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
    } else if (args.action === "edit") {
      sendData(args?.data, "update");
    } else if (args.action === "add") {
      sendData(args?.data, "add");
    }
  };

  // Function to send context menu data (email/phone) to the server
  const sendContextData = () => {
    const updatedData = changes.map(
      (item) =>
        (item?.action === "add" && {
          action: item?.action,
          itemId: item?.itemId,
          featureType: featureTypeList?.data?.filter(
            (type) => type?.UVDESC === item?.featureType
          )?.[0]?.UVVALUE,
          featureDesc: item?.featureDesc,
          featureUnit: unitsList?.data?.filter(
            (unit) => unit?.UVDESC === item?.featureUnitDesc
          )?.[0]?.UVVALUE,
        }) ||
        (item?.action === "update" && {
          action: item?.action,
          itemId: item?.itemId,
          featureId: item?.featureId,
          featureType: featureTypeList?.data?.filter(
            (type) => type?.UVDESC === item?.featureType
          )?.[0]?.UVVALUE,
          featureDesc: item?.featureDesc,
          featureUnit: unitsList?.data?.filter(
            (unit) => unit?.UVDESC === item?.featureUnitDesc
          )?.[0]?.UVVALUE,
        }) ||
        (item?.action === "delete" && {
          action: item?.action,
          itemId: item?.itemId,
          featureId: item?.featureId,
        })
    );

    const type =
      openContextMenu?.menu === "itemFeatures"
        ? "itemsFeature"
        : "updateItemCommunication";
    socketproject.emit(
      "automation:web:admin:usr:v1",
      {
        request: "inventory",
        inventory: {
          type: type,
          [type]: updatedData,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.messageType,
          })
        );
        handleClose(); // Close popup after submission
      }
    );
  };

  const contextMenuItems = [
    { text: "Item Features", target: ".e-content", id: "itemFeatures" },
    {
      text: "Item Communication",
      target: ".e-content",
      id: "itemCommunication",
    },
  ];

  const contextMenuClick = (args, gridInstance) => {
    if (gridInstance && args.item.id === "itemFeatures") {
      getContextMenuData("itemsFeature", args?.rowInfo?.rowData?.["itemId"]); // Fetch data for email
      openContextMenuPopUp("itemFeatures", args?.rowInfo?.rowData); // Open context menu for emails
    } else if (gridInstance && args.item.id === "itemCommunication") {
      getContextMenuData(
        "itemCommunication",
        args?.rowInfo?.rowData?.["itemId"]
      ); // Fetch data for phone number
      openContextMenuPopUp("itemCommunication", args?.rowInfo?.rowData); // Open context menu for phone numbers
    }
  };

  const onDelete = () => {
    sendData(selectedRow?.itemId, "delete");
  };

  return (
    <div
      style={{
        marginTop: "1%", // Adding margin to position the DataGrid
        zIndex: "0", // Ensuring correct layering
        display: "flex", // Flexbox to center content
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {itemsMasterData && ( // Conditionally render DataGrid when data is available
        <div
          style={{
            opacity: openContextMenu?.open && "0.3", // Dim the DataGrid when context menu is open
          }}
        >
          <SingleHierarchy
            columns={ColumnDirective} // Passing column definitions
            data={itemsMasterData} // Passing data to the DataGrid
            primaryKey={"itemId"} // Setting primary key
            openContextMenuPopUp={openContextMenuPopUp} // Passing function to open context menu
            getContextMenuData={getContextMenuData} // Passing function to fetch context menu data
            selection={"Single"} // Single selection mode
            onChanges={onChanges} // Passing function to handle changes
            contextMenu={true} // Enabling context menu
            setRowSelected={setSelectedRow}
            readOnly={false}
            contextMenuItems={contextMenuItems}
            contextMenuClick={(args, gridInstance) =>
              contextMenuClick(args, gridInstance)
            }
            onDelete={onDelete}
            handleClose={handleClose}
          />
        </div>
      )}
      {openContextMenu?.open && ( // Conditionally render context menu popup
        <MyAlertDialog
          open={openContextMenu?.open}
          handleClose={handleClose}
          title={
            openContextMenu?.menu === "itemFeatures"
              ? "Item Features"
              : "Item Communication"
          } // Display appropriate title
          content={
            // Render context menu content
            <ContextMenuItemsComponent
              menu={openContextMenu?.menu}
              data={openContextMenu?.data}
              changes={changes}
              setChanges={setChanges}
              isContextMenuLoading={isContextMenuLoading}
              units={units}
              featureType={featureType}
            />
          }
          buttonTitle={"Save"}
          buttonClick={sendContextData} // Send changes on button click
        />
      )}
      {/* {deletePopUp?.open && ( // Conditionally render context menu popup
        <MyAlertDialog
          open={deletePopUp?.open}
          handleClose={handleClose}
          title={"Are you sure?"} // Display appropriate title
          buttonTitle={"Delete"}
          buttonClick={() => {
            sendData(deletePopUp?.data, deletePopUp?.action);
            setDeletePopUp({
              open: false,
              data: [],
              action: "delete",
            });
          }} // Send changes on button click
        />
      )} */}
    </div>
  );
};

export default ItemsMasterBody; // Exporting the component as default
