import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import BasicTextFields from "../../../../../Common/TextField";
import BasicButtons from "../../../../../Common/Button";
import { useSelector } from "react-redux";
import SingleHierarchy from "../../../../../Common/DataGrid/singleGrid";

const CompanyIDPopUp = ({ setCompanyIdRowSelected }) => {
  const companyList = useSelector((state) => state?.addressBook?.companyList);

  const ColumnCompanyDirective = [
    {
      field: "UVVALUE",
      headerText: "Value",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true, // ID is not editable
    },
    {
      field: "UVID",
      headerText: "ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
    },
    { field: "UVDESC", headerText: "Description", width: "200" },
  ];

  return (
    <Box sx={{ marginTop: "3%" }}>
      {companyList?.data?.length > 0 && (
        <Grid container>
          {/* <Grid sx={{ width: "30%" }}>
          <BasicTextFields
            //   value={masterInfoFilter?.addressNb}
            id="addressNb"
            //   onChange={(e) => handleChange(e, "addressNb")}
            label="Address Nb"
          />
        </Grid>
        <Grid sx={{ width: "5%" }}></Grid>
        <Grid sx={{ width: "15%", display: "flex", alignItems: "center" }}>
          <BasicButtons
            //   onClick={handleSubmit}
            text="Search"
            sx={{ width: "100%", height: "100%", padding: "5% !important" }}
            //   disabled={username === "" || pwd === ""} // Disable button if inputs are empty
          />
        </Grid> */}
          <SingleHierarchy
            columns={ColumnCompanyDirective}
            data={JSON.parse(JSON.stringify(companyList?.data))} // Clone contextMenuData to avoid mutations
            primaryKey={"UVVALUE"} // Specify primary key for the grid
            selection={"Single"} // Allow multiple selection in the grid
            contextMenu={false} // Disable built-in context menu
            readOnly={true}
            setRowSelected={setCompanyIdRowSelected}
          />
        </Grid>
      )}
    </Box>
  );
};

export default CompanyIDPopUp;
