import { Grid2 } from "@mui/material";
import React from "react";
import BoxItem from "./BoxItem";

const BigBoxItems = ({
  lBox,
  focus,
  setFocus,
  onFocus,
  onBlur,
  sectionId,
  onEditClick,
  pageId,
}) => {
  return (
    <Grid2 width={"100%"} marginY={"1%"} height={"100%"}>
      <BoxItem
        title={lBox?.appTitle}
        description={lBox?.appDesc}
        image={lBox?.appImgURL}
        focus={focus}
        setFocus={setFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        sectionId={sectionId}
        data={lBox}
        onEditClick={onEditClick}
        pageId={pageId}
      />
    </Grid2>
  );
};

export default BigBoxItems;
