import React from "react";
import JsonArrays from "../../../JSON";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const CommonTab = ({ state, onChange }) => {
  return (
    <div id="tab0" className="tab">
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "16px" }}
          >
            <input
              id="pageId"
              name="pageId"
              type="text"
              disabled={true}
              value={state.pageId || ""}
              onChange={onChange}
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Page ID</label>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "10px" }}
          >
            <DropDownListComponent
              id="pageType"
              name="pageType"
              value={state.pageType}
              dataSource={JsonArrays.type}
              fields={{ text: "title", value: "title" }}
              placeholder="Type"
              popupHeight="300px"
              floatLabelType="Always"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "10px" }}
          >
            <DropDownListComponent
              id="pageTarget"
              name="pageTarget"
              value={state.pageTarget}
              dataSource={JsonArrays.targets}
              fields={{ text: "title", value: "title" }}
              placeholder="Target"
              popupHeight="300px"
              floatLabelType="Always"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "10px" }}
          >
            <DropDownListComponent
              id="platform"
              name="platform"
              value={state.platform}
              dataSource={JsonArrays.platforms}
              fields={{ text: "title", value: "title" }}
              placeholder="Platform"
              popupHeight="300px"
              floatLabelType="Always"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <input
              id="pageCategory"
              name="pageCategory"
              type="text"
              value={state.pageCategory || ""}
              onChange={onChange}
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Category</label>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <input
              id="pageIcon"
              name="pageIcon"
              type="text"
              value={state.pageIcon || ""}
              onChange={onChange}
            />
            {/* <input
                id="pageIcon"
                name="pageIcon"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    // Handle the uploaded file (e.g., preview, upload to server, etc.)
                    console.log(file);
                    onChange({ target: { name: "pageIcon", value: file } });
                  }
                }}
                style={{ height: "100%" }}
              /> */}
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Image</label>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <input
              id="pageRoute"
              name="pageRoute"
              type="text"
              value={state.pageRoute || ""}
              onChange={onChange}
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Route</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTab;
