import { Box, CircularProgress, Grid } from "@mui/material"; // Importing Material-UI components for layout and loading indicator
import React, { useState } from "react"; // Importing React and useState hook
import { useDispatch, useSelector } from "react-redux";
import UserMenuFilterReturn from "./FilterReturn";
import FilterComponent from "../../../Common/Filter";
import { setUserMenuData } from "../../../../Services/Redux/Reducers/userFeaturesReducer";
import UserMenuBody from "./Body";
import Cookies from "universal-cookie";
import { setNotificationSnackBar } from "../../../../Services/Redux/Reducers/authSliceReducer";

const UserMenuComponent = () => {
  const socketadmin = useSelector((state) => state.auth.socketadmin); // Accessing socketadmin from the Redux store
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status

  const userMenuFilter = useSelector(
    (state) => state?.userFeatures?.userMenuFilter
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch
  const cookies = new Cookies();

  // const [openGraphicUpdate, setOpenGraphicUpdate] = useState({
  //   open: false,
  //   data: {},
  // });

  // Function to handle search logic by sending a socket event
  const onSearch = () => {
    setIsLoading(true); // Set loading state to true when the search starts
    socketadmin.emit(
      "automation:web:admin:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "menus", // Specifying the search type
          menus: {
            searchTxt: userMenuFilter?.searchTxt,
          },
        },
      },
      (response) => {
        dispatch(setUserMenuData(response?.data));
        setIsLoading(false); // Set loading state to false when the search completes
      }
    );
  };

  return (
    <Box sx={{ color: "black" }}>
      {" "}
      {/* Main container with text color set to black */}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        {/* {!openGraphicUpdate?.open && ( */}
        <FilterComponent
          children={<UserMenuFilterReturn onSearch={onSearch} />} // Rendering filter component with loading state handler
        />
        {/* )} */}
        {!isLoading && <UserMenuBody onSearch={onSearch} />}
      </Grid>
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Spinner color
              width: "200px !important", // Setting width
              height: "200px !important", // Setting height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default UserMenuComponent; // Exporting the component for use in other parts of the application
