import { Box, Grid } from "@mui/material"; // Importing Box and Grid components from MUI for layout
import React from "react"; // Importing React
import BasicTextFields from "../../../../Common/TextField"; // Importing custom BasicTextFields component for input fields
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import BasicButtons from "../../../../Common/Button"; // Importing a custom Button component
import BasicSelect from "../../../../Common/Select"; // Importing a custom Select component
import { setMenuPagesFilter } from "../../../../../Services/Redux/Reducers/userFeaturesReducer"; // Redux action for updating filter state
import JsonArrays from "../JSON"; // Importing JSON arrays for dropdown data

/**
 * MenuPagesFilterReturn component
 * Renders a filter form for menu pages using dropdowns and a search text field.
 *
 * Props:
 * - onSearch: Function to handle the search action.
 */
const MenuPagesFilterReturn = ({ onSearch }) => {
  // Selecting the current filter state from the Redux store
  const menuPagesFilter = useSelector(
    (state) => state?.userFeatures?.menuPagesFilter
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch

  /**
   * Handles changes to input fields or dropdowns and updates the Redux state.
   * @param {Event} e - The event object from the input field or dropdown.
   * @param {string} item - The key in the filter object to update.
   */
  const handleChange = (e, item) => {
    dispatch(
      setMenuPagesFilter({
        ...menuPagesFilter, // Retain previous filter values
        [item]: e?.target?.value, // Update the specific filter key with the new value
      })
    );
  };

  return (
    <Box>
      <Grid container>
        {/* Dropdown for selecting language */}
        <Grid sx={{ width: "8%" }}>
          <BasicSelect
            label={"Language"} // Label for the dropdown
            value={menuPagesFilter?.language} // Value linked to Redux filter state
            onChange={(e) => handleChange(e, "language")} // Update filter state on change
            data={JsonArrays?.language} // Options for the dropdown
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer for layout */}
        {/* Dropdown for selecting target */}
        <Grid sx={{ width: "8%" }}>
          <BasicSelect
            label={"Target"} // Label for the dropdown
            value={menuPagesFilter?.target} // Value linked to Redux filter state
            onChange={(e) => handleChange(e, "target")} // Update filter state on change
            data={JsonArrays?.targets} // Options for the dropdown
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer for layout */}
        {/* Dropdown for selecting platform */}
        <Grid sx={{ width: "8%" }}>
          <BasicSelect
            label={"Platform"} // Label for the dropdown
            value={menuPagesFilter?.platform} // Value linked to Redux filter state
            onChange={(e) => handleChange(e, "platform")} // Update filter state on change
            data={JsonArrays?.platforms} // Options for the dropdown
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer for layout */}
        {/* Input field for search text */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={menuPagesFilter?.searchTxt} // Value linked to Redux filter state
            id="searchTxt" // Unique ID for the input
            onChange={(e) => handleChange(e, "searchTxt")} // Update filter state on change
            label="Search Text" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer for layout */}
        {/* Search button to trigger the search action */}
        <Grid sx={{ width: "8%", display: "flex" }}>
          <BasicButtons text={"Search"} onClick={onSearch} />{" "}
          {/* Trigger search */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MenuPagesFilterReturn; // Exporting the component as default
