import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CommonTab from "./tabs/common";
import LanguagesTab from "./tabs/languages";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const CustomizeTemplate = (props) => {
  const [state, setState] = useState({ ...props[0], currentTab: 0 });
  const [value, setValue] = useState("0");

  const languagesData = useSelector(
    (state) => state?.userFeatures?.languagesData
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    props?.setUpdatedChanges((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Common" value="0" />
              <Tab label="Languages" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <CommonTab state={state} onChange={onChange} />
          </TabPanel>
          <TabPanel value="1">
            <LanguagesTab
              languagesData={languagesData}
              state={state}
              onChange={onChange}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default CustomizeTemplate;
