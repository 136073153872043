import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Layout from "./components/Pages/Layout";
import Login from "./components/Pages/Login/Login";
import RequireAuth from "./Services/RTKQuery/auth/RequireAuth";
import { MissingRoute } from "./Services/MissingRoutes";
import { useDispatch, useSelector } from "react-redux";
import ForgetPasswordComponent from "./components/Pages/ForgetPassword";
import SocketIO from "./Services/SocketIO";
import { useEffect } from "react";
import {
  setNotificationSnackBar,
  setSocketAdmin,
  setSocketProject,
} from "./Services/Redux/Reducers/authSliceReducer";
import MasterInfoComponent from "./components/Pages/AddressBook/MasterInfo";
import HomeComponent from "./components/Pages/Home";
import Cookies from "universal-cookie";
import { Box } from "@mui/material";
import CustomizedSnackbars from "./components/Common/NotificationAlert";
import ItemsMasterComponent from "./components/Pages/Inventory/ItemsMaster";
import MenuPagesComponent from "./components/Pages/UserFeatures/MenuPages";
import UserMenuComponent from "./components/Pages/UserFeatures/UserMenu";

function App() {
  const user = useSelector((state) => state.auth.user);
  const userAll = useSelector((state) => state.auth.userAll);
  const dispatch = useDispatch();
  const socketLabels = useSelector((state) => state.auth.socketLabels);
  const socketadmin = useSelector((state) => state.auth.socketadmin);
  const socketproject = useSelector((state) => state.auth.socketproject);
  const cookies = new Cookies();
  const location = useLocation();
  const notificationSnackBar = useSelector(
    (state) => state.auth.notificationSnackBar
  );

  useEffect(() => {
    if (location?.pathname !== "/" && location?.pathname !== undefined) {
      cookies.set("_location", location?.pathname, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    } else {
      cookies.set("_location", "/main", {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    }
  }, [location?.pathname]);

  const filterLabels = (label) => {
    const filtered = socketLabels?.filter((e) => {
      if (e?.indexOf(",") > -1) {
        const isTrue =
          e?.split(", ")?.[0] === label || e?.split(", ")?.[1] === label;
        return isTrue;
      } else {
        return e?.includes(label);
      }
    });
    return filtered?.length > 0;
  };

  const connection = (label, socket, path, setSocketAction) => {
    if (filterLabels(label) && !socket && user) {
      const socket2 = SocketIO(
        userAll?.token,
        path,
        label === "all" ? true : false
      ).connect();

      if (socket2 !== socket) {
        dispatch(setSocketAction(socket2));

        // if (label === "mrbc" && socket2) {
        // socket2.on("dspinternal:srv:mrbc:spv:v1", (response) => {
        //   console.log(response);
        //   if (
        //     response?.request === "mrbcEmission" &&
        //     response?.mrbcEmission?.type === "emissionInterfaceImport"
        //   ) {
        //     dispatch(setIntegrationProgress(response?.mrbcEmission?.data));
        //     dispatch(setIntegrationLogs(response?.mrbcEmission?.data));
        //   }
        // });
        // }

        socket2.on("connect", () => {
          console.log(`Socket ${label} connected`);
          console.log(socket2);
        });

        socket2.on("disconnect", () => {
          console.log(`Socket ${label} disconnected`);
        });
      }
    }
  };

  useEffect(() => {
    connection(
      "admin",
      socketadmin,
      process.env.REACT_APP_API_SOCKETIOADMIN_PATH,
      setSocketAdmin
    );
  }, [socketadmin]);

  useEffect(() => {
    connection(
      "project",
      socketproject,
      process.env.REACT_APP_API_SOCKETIOPROJECT_PATH,
      setSocketProject
    );
  }, [socketproject]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setNotificationSnackBar({
        open: false,
        message: "",
        type: "",
      })
    );
  };

  const pages = [
    {
      path: "/",
      element: !user ? <Login /> : <Navigate to={"main/home"} />,
      childs: [],
    },
    {
      path: "/forgetPassword",
      element: <ForgetPasswordComponent />,
      childs: [],
    },
    {
      path: "*",
      element: <MissingRoute />,
      childs: [],
    },
    {
      path: "/main/*",
      element: user ? <RequireAuth /> : <Navigate to={"/"} />,
      childs: [
        {
          path: "home/*",
          element: user ? <HomeComponent /> : <Navigate to={"/"} />,
          childs: [
            {
              path: "addressBook001a",
              element: user ? <MasterInfoComponent /> : <Navigate to={"/"} />,
              childs: [],
            },
            {
              path: "invItems001a",
              element: user ? <ItemsMasterComponent /> : <Navigate to={"/"} />,
              childs: [],
            },
            {
              path: "pages001",
              element: user ? <MenuPagesComponent /> : <Navigate to={"/"} />,
              childs: [],
            },
            {
              path: "userMenu001",
              element: user ? <UserMenuComponent /> : <Navigate to={"/"} />,
              childs: [],
            },
          ],
        },
      ],
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Routes>
        {pages?.map((page, i) => {
          return (
            <Route key={i} path={page?.path} element={page?.element}>
              {page?.childs?.map((child1, i) => {
                return (
                  <Route key={i} path={child1?.path} element={child1?.element}>
                    {child1?.childs?.map((child2, i) => {
                      return (
                        <Route
                          key={i}
                          path={child2?.path}
                          element={child2?.element}
                          action={child2?.path === cookies.get("_location")}
                        />
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Routes>
      {notificationSnackBar?.open && (
        <CustomizedSnackbars
          open={notificationSnackBar?.open}
          handleClose={handleCloseSnackBar}
          severity={notificationSnackBar?.type}
          message={notificationSnackBar?.message}
        />
      )}
    </Box>
  );
}

export default App;

{
  /* Public routes */
}
//    <Route path="/" element={!user ? <Layout /> : <Navigate to={"main"} />}>
//    <Route index element={<Login />} /> {/* Default route */}
//    <Route path="*" element={<MissingRoute />} />
//    <Route path="forgetPassword" element={<ForgetPasswordComponent />} />
//  </Route>

{
  /* Protected routes */
}
//  <Route
//    path="/main/*"
//    element={user ? <RequireAuth /> : <Navigate to={"/"} />}
//  >
//    <Route
//      path="home/*"
//      element={user ? <HomeComponent /> : <Navigate to={"/"} />}
//    >
//      <Route
//        path="addressBook001a"
//        element={user ? <MasterInfoComponent /> : <Navigate to={"/"} />}
//      />
//    </Route>
{
  /* <Route path="statistics" element={<Statistics />} /> */
}
{
  /* <Route path="settings" element={<Settings />} /> */
}
//  </Route>
