import { Box, Grid } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  setPagesDataChanges,
  setUpdatePageData,
} from "../../../../../../../Services/Redux/Reducers/userFeaturesReducer";

// Functional component for rendering an individual BoxItem
const BoxItem = (props) => {
  const dispatch = useDispatch(); // Redux dispatch hook

  // Accessing state slices from Redux store
  const updatePageData = useSelector(
    (state) => state?.userFeatures?.updatePageData
  );

  const pagesDataChanges = useSelector(
    (state) => state?.userFeatures?.pagesDataChanges
  );

  /**
   * Deletes an app from the sections in updatePageData and pagesDataChanges
   * @param {string} sectionId - The ID of the section containing the app
   * @param {number} appId - The ID of the app to be deleted
   */
  const deleteApp = (sectionId, appId) => {
    // Special handling for apps with appId between 0 and 1
    if (appId > 0 && appId < 1) {
      // Update `updatePageData.sections` by removing the app
      const updatedSections = updatePageData?.sections?.map((section) => {
        if (section?.sectionId === sectionId) {
          return {
            ...section,
            apps: section?.apps?.filter((app) => app.appId !== appId),
          };
        }
        return section;
      });

      // Update `pagesDataChanges.sections` by removing the app
      const updatedPagesDataChanges = {
        ...pagesDataChanges,
        sections: pagesDataChanges?.sections?.map((section) => {
          if (section.sectionId === sectionId) {
            return {
              ...section,
              apps: section.apps.filter((app) => app.appId !== appId),
            };
          }
          return section;
        }),
      };

      // Dispatch the updates to the Redux store
      dispatch(
        setUpdatePageData({ ...updatePageData, sections: updatedSections })
      );
      dispatch(setPagesDataChanges(updatedPagesDataChanges));
      return;
    }

    // Default handling for other appId values
    const updatedSections = updatePageData?.sections?.map((section) => {
      if (section?.sectionId === sectionId) {
        return {
          ...section,
          apps: section?.apps?.filter((app) => app.appId !== appId),
        };
      }
      return section;
    });

    // Prepare a "delete" change object for the app
    const change = {
      pageId: updatePageData?.pageId,
      sectionId,
      appId,
      action: "delete",
    };

    // Check if the section already exists in pagesDataChanges
    const sectionExistsInPagesDataChanges = pagesDataChanges?.sections?.some(
      (section) => section.sectionId === sectionId
    );

    let updatedPagesDataChanges = { ...pagesDataChanges };

    // If the section doesn't exist, add it to pagesDataChanges with "none" action
    if (!sectionExistsInPagesDataChanges) {
      const sectionToAdd = updatePageData?.sections?.find(
        (section) => section.sectionId === sectionId
      );
      if (sectionToAdd) {
        updatedPagesDataChanges.sections = [
          ...(updatedPagesDataChanges?.sections || []),
          { ...sectionToAdd, action: "none" },
        ];
      }
    }

    // Update pagesDataChanges with the delete action
    updatedPagesDataChanges = {
      ...updatedPagesDataChanges,
      sections: updatedPagesDataChanges?.sections?.map((section) => {
        if (section.sectionId === sectionId) {
          return {
            ...section,
            apps: section.apps.filter((app) => app.appId !== appId),
          };
        }
        return section;
      }),
    };

    // Add the change to the section's app list in pagesDataChanges
    const sectionIndex = updatedPagesDataChanges.sections?.findIndex(
      (section) => section.sectionId === sectionId
    );

    if (sectionIndex > -1) {
      updatedPagesDataChanges.sections[sectionIndex].apps = [
        ...(updatedPagesDataChanges.sections[sectionIndex].apps || []),
        change,
      ];
    } else {
      updatedPagesDataChanges.sections = [
        ...(updatedPagesDataChanges.sections || []),
        { sectionId, apps: [change] },
      ];
    }

    // Dispatch updated data to Redux store
    dispatch(
      setUpdatePageData({ ...updatePageData, sections: updatedSections })
    );
    dispatch(setPagesDataChanges(updatedPagesDataChanges));
  };

  // Rendering the component
  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: "#c4d0d6",
        width: "100%",
        height: "100%",
        color: "#175779",
      }}
    >
      <Grid container>
        {/* Image Grid */}
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.image !== "null" && (
            <img alt="" src={props.image} style={{ width: "50%" }} />
          )}
        </Grid>
        {/* Content Grid */}
        <Grid
          item
          xs={9}
          sx={{ display: "flex", alignItems: "center", position: "relative" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto", paddingLeft: "0px" }}>
              <Typography
                component="div"
                sx={{ fontWeight: "bold", fontSize: "1.6rem" }}
              >
                {props?.title}
              </Typography>
              <Typography
                variant="h6"
                color="text.secondary"
                component="div"
                sx={{ color: "#175779" }}
              >
                {props?.description}
              </Typography>
            </CardContent>
          </Box>
          {/* Action Buttons Grid */}
          <Grid
            item
            xs={1}
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => props?.onEditClick(props?.data)}
            >
              <EditIcon sx={{ color: "#616060" }} />
            </button>
            <button style={{ backgroundColor: "transparent", border: "none" }}>
              <DeleteIcon
                sx={{ color: "#bd3c3c" }}
                onClick={() => deleteApp(props?.sectionId, props?.data?.appId)}
              />
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BoxItem;
