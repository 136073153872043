import React, { useState } from "react";
import DualListBox from "../../../../../Common/DualListBox";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

const CutomizedEditTemplate = (props) => {
  const [state, setState] = useState({ ...(props?.[0] || {}) });
  const [menuPages, setMenuPages] = useState(props?.[0]?.pages);

  const setUpdateChanges = props?.setUpdateChanges;
  const updateChanges = props?.updateChanges;

  const titles = ["All Pages", "My Pages"];
  const allPagesData = useSelector(
    (state) => state?.userFeatures?.userMenuData
  )?.allPages;
  const userMenuData = useSelector(
    (state) => state?.userFeatures?.userMenuData
  )?.menus;

  const data = () => {
    const specific =
      userMenuData
        ?.find((obj) => obj?.menuId === props?.[0]?.menuId)
        ?.pages?.map(({ pageId, pageDesc, pageIcon, pageRoute }) => ({
          pageId: pageId,
          text: pageDesc,
          description: pageRoute,
          icon: pageIcon,
        })) || [];

    const idsToRemove = specific?.map((obj) => obj?.pageId);

    const all =
      allPagesData
        ?.map(({ pageId, pageDesc, pageIcon, pageRoute }) => ({
          pageId: pageId,
          text: pageDesc,
          description: pageRoute,
          icon: pageIcon,
        }))
        ?.filter((item) => !idsToRemove?.includes(item.pageId)) || [];

    return {
      groupa: all,
      groupb: specific,
    };
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // if (updateChanges?.length <= 0) {
    setUpdateChanges(menuPages);
    // }
  };

  const onChanges = (e) => {
    if (e?.eventName === "moveAllTo") {
      setMenuPages(allPagesData);
      setUpdateChanges(allPagesData);
    } else if (e?.eventName === "moveAllFrom") {
      setMenuPages([]);
      setUpdateChanges([]);
    } else if (e?.eventName === "moveTo") {
      setMenuPages([...menuPages, e?.items?.[0]]);
      setUpdateChanges([...menuPages, e?.items?.[0]]);
    } else if (e?.eventName === "moveFrom") {
      const removed = menuPages?.filter(
        (item) => item.pageId !== e?.items?.[0]?.pageId
      );
      setMenuPages(removed);
      setUpdateChanges(removed);
    }
  };

  return (
    <Box>
      <Grid>
        <div id="tab0" className="tab" style={{ display: "ruby" }}>
          <div className="form-row" style={{ width: "50%" }}>
            <div
              className="form-group col-md-6"
              style={{ width: "100%", marginBottom: "0px" }}
            >
              <div
                className="e-float-input e-control-wrapper"
                style={{ marginTop: "16px" }}
              >
                <input
                  id="menuId"
                  name="menuId"
                  type="text"
                  disabled={true}
                  value={state.menuId || ""}
                  onChange={onChange}
                />
                <span className="e-float-line" />
                <label className="e-float-text e-label-top">Menu ID</label>
              </div>
            </div>
          </div>
          <div className="form-row" style={{ width: "50%" }}>
            <div
              className="form-group col-md-6"
              style={{
                width: "100%",
                marginBottom: "0px",
                marginTop: "10px",
              }}
            >
              <div className="e-float-input e-control-wrapper">
                <input
                  id="menuDesc"
                  name="menuDesc"
                  type="text"
                  value={state.menuDesc || ""}
                  onChange={onChange}
                />
                <span className="e-float-line" />
                <label className="e-float-text e-label-top">Description</label>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <DualListBox data={data()} titles={titles} onChanges={onChanges} />
    </Box>
  );
};

export default CutomizedEditTemplate;
