import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import GraphicalPagesUpdate from "./GraphicUpdate";
import {
  setPagesDataChanges,
  setUpdatePageData,
} from "../../../../../Services/Redux/Reducers/userFeaturesReducer";
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid";
import JsonArrays from "../JSON";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer";
import CustomizeTemplate from "./CustomizeTemplate";
import CustomizeEditTemplate from "./CustomizeEditTemplate";

// Component for managing menu pages
const MenuPagesBody = ({
  openGraphicUpdate, // State for managing graphic update modal
  setOpenGraphicUpdate, // Function to toggle graphic update modal
  onSearch, // Function for search functionality
}) => {
  const socketadmin = useSelector((state) => state.auth.socketadmin); // Socket connection instance
  const menuPagesData = useSelector(
    (state) => state?.userFeatures?.menuPagesData
  ); // Menu pages data from Redux store
  const updatePageData = useSelector(
    (state) => state?.userFeatures?.updatePageData
  ); // Page data for update

  const menuPagesFilter = useSelector(
    (state) => state?.userFeatures?.menuPagesFilter
  );

  const dispatch = useDispatch();

  const [updatedChanges, setUpdatedChanges] = useState({
    pageId: "",
    pageTitle: "",
    pageDesc: "",
    pageFrTilte: "",
    pageFrDesc: "",
    pageType: "",
    pageTarget: "",
    platform: "",
    pageCategory: "",
    pageIcon: "",
    pageRoute: "",
  });

  // Open graphic update modal with row data
  const openGraphic = (e) => {
    const modifiedData = { ...e };
    delete modifiedData.column;
    delete modifiedData.sections;

    setOpenGraphicUpdate({
      open: true,
      data: e,
    });

    dispatch(setUpdatePageData(e));
    dispatch(setPagesDataChanges(modifiedData));
  };

  // Close graphic update modal
  const handleClose = () => {
    setOpenGraphicUpdate({
      open: false,
      data: {},
    });
    dispatch(setPagesDataChanges([]));
  };

  // Dropdown configuration for the "Target" column
  const editTargetParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input";
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value,
    write: (args) => {
      new DropDownList({
        dataSource: JsonArrays.targets,
        fields: { text: "title", value: "title" },
        value: args.rowData[args.column.field] || null,
        popupHeight: "300px",
        placeholder: "Target",
        floatLabelType: "Always",
      }).appendTo(args.element);
    },
  };

  // Dropdown configuration for the "Platform" column
  const editPlatformParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input";
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value,
    write: (args) => {
      new DropDownList({
        dataSource: JsonArrays.platforms,
        fields: { text: "title", value: "title" },
        value: args.rowData[args.column.field] || null,
        popupHeight: "300px",
        placeholder: "Platform",
        floatLabelType: "Always",
      }).appendTo(args.element);
    },
  };

  // Dropdown configuration for the "Type" column
  const editTypeParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input";
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value,
    write: (args) => {
      new DropDownList({
        dataSource: JsonArrays.type,
        fields: { text: "title", value: "title" },
        value: args.rowData[args.column.field] || null,
        popupHeight: "300px",
        placeholder: "Type",
        floatLabelType: "Always",
      }).appendTo(args.element);
    },
  };

  // Column definitions for DataGrid
  const ColumnDirective = [
    {
      field: "pageId",
      headerText: "Page ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
      allowEditing: false,
    },
    { field: "pageTitle", headerText: "Title", width: "100" },
    { field: "pageFrTitle", headerText: "Title", width: "100", visible: false },
    { field: "pageDesc", headerText: "Description", width: "100" },
    {
      field: "pageFrDesc",
      headerText: "Description",
      width: "100",
      visible: false,
    },
    {
      field: "pageType",
      headerText: "Type",
      width: "100",
      edit: editTypeParams,
    },
    {
      field: "pageTarget",
      headerText: "Target",
      width: "100",
      edit: editTargetParams,
    },
    {
      field: "platform",
      headerText: "Platform",
      width: "100",
      edit: editPlatformParams,
    },
    { field: "pageCategory", headerText: "Category", width: "100" },
    { field: "pageIcon", headerText: "Image", width: "100" },
    { field: "pageRoute", headerText: "Route", width: "100" },
  ];

  // Function to send data via socket connection
  const sendData = (data, action) => {
    const sendedData =
      (action === "add" && {
        action: "add",
        pageIcon: updatedChanges?.pageIcon ? updatedChanges?.pageIcon : "",
        pageCategory: updatedChanges?.pageCategory
          ? updatedChanges?.pageCategory
          : " ",
        platform: updatedChanges?.platform
          ? (updatedChanges?.platform === "All" && "mob,web") ||
            (updatedChanges?.platform === "Web" && "web") ||
            (updatedChanges?.platform === "Mobile" && "mobile")
          : "",
        pageTarget: updatedChanges?.pageTarget
          ? (updatedChanges?.pageTarget === "User" && "user") ||
            (updatedChanges?.pageTarget === "Admin" && "admin")
          : "",
        pageType: updatedChanges?.pageType
          ? (updatedChanges?.pageType === "Solo" && "solo") ||
            (updatedChanges?.pageType === "Composite" && "composite")
          : "",
        pageRoute: updatedChanges?.pageRoute ? updatedChanges?.pageRoute : "",
        language: [
          {
            lan: "en",
            pageTitle: updatedChanges?.pageTitle
              ? updatedChanges?.pageTitle
              : "",
            pageDesc: updatedChanges?.pageDesc ? updatedChanges?.pageDesc : "",
          },
          {
            lan: "fr",
            pageTitle: updatedChanges?.pageFrTitle
              ? updatedChanges?.pageFrTitle
              : "",
            pageDesc: updatedChanges?.pageFrDesc
              ? updatedChanges?.pageFrDesc
              : "",
          },
        ],
      }) ||
      (action === "update" && {
        action: "update",
        pageId: data?.pageId,
        pageDesc: data?.pageDesc ? data?.pageDesc : "",
        language: menuPagesFilter?.language === "EN" ? "en" : "fr",
        pageIcon: data?.pageIcon ? data?.pageIcon : "",
        pageCategory: data?.pageCategory ? data?.pageCategory : " ",
        platform: data?.platform
          ? (data?.platform === "All" && "mob,web") ||
            (data?.platform === "Web" && "web") ||
            (data?.platform === "Mobile" && "mobile")
          : "",
        pageTarget: data?.pageTarget
          ? (data?.pageTarget === "User" && "user") ||
            (data?.pageTarget === "Admin" && "admin")
          : "",
        pageTitle: data?.pageTitle ? data?.pageTitle : "",
        pageType: data?.pageType
          ? (data?.pageType === "Solo" && "solo") ||
            (data?.pageType === "Composite" && "composite")
          : "",
        pageRoute: data?.pageRoute ? data?.pageRoute : "",
      }) ||
      (action === "delete" && {
        action: "delete",
        pageId: data?.pageId,
      });
    socketadmin.emit(
      "automation:web:admin:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "pageUpdate", // Specifying the search type
          pageUpdate: sendedData,
        },
      },
      (response) => {
        if (response?.responseStatus === "success") {
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: response?.message?.text, // Display the response message
              type: response?.message?.messageType, // Set notification type
            })
          );
          onSearch();
        } else {
          // If the server response indicates failure, show a notification
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: response?.message?.text, // Display the response message
              type: response?.message?.messageType, // Set notification type
            })
          );
        }
      }
    );
  };

  // Handle changes in DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      sendData(args?.promise?.[0], "delete");
    } else if (args.action === "edit") {
      const updatedData = { ...args.data };
      delete updatedData.sections;
      sendData(updatedData, "update");
    } else if (args.action === "add") {
      sendData(args.data, "add");
    }
  };

  // Context menu items and their actions
  const contextMenuItems = [
    {
      text: "Languages",
      target: ".e-content",
      id: "languages",
      iconCss: "e-icons e-character-style",
    },
    "Edit",
    "Delete",
    {
      text: "Customize",
      target: ".e-content",
      id: "customize",
      iconCss: "e-icons e-properties-1",
    },
  ];

  const contextMenuClick = (args, gridInstance) => {
    if (args.item.id === "customize") {
      openGraphic(args?.rowInfo?.rowData);
    }
  };

  const dialogTemplate = (props, grid) => {
    const a = [props, grid];
    if (props?.isAdd) {
      return <CustomizeTemplate {...a} setUpdatedChanges={setUpdatedChanges} />;
    } else {
      return (
        <CustomizeEditTemplate {...a} setUpdatedChanges={setUpdatedChanges} />
      );
    }
  };

  return (
    <Grid>
      {!openGraphicUpdate?.open ? (
        <SingleHierarchy
          columns={ColumnDirective}
          data={JSON.parse(JSON.stringify(menuPagesData))}
          primaryKey={"pageId"}
          selection={"Single"}
          onChanges={onChanges}
          contextMenu={true}
          readOnly={false}
          required={true}
          contextMenuItems={contextMenuItems}
          contextMenuClick={(args, gridInstance) =>
            contextMenuClick(args, gridInstance)
          }
          mode={"noPDF"}
          template={(props, grid) => dialogTemplate(props, grid)}
          style={true}
          someChanges={updatedChanges}
        />
      ) : (
        <GraphicalPagesUpdate
          data={updatePageData}
          handleClose={handleClose}
          setOpenGraphicUpdate={setOpenGraphicUpdate}
          onSearch={onSearch}
        />
      )}
    </Grid>
  );
};

export default MenuPagesBody;
