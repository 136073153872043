import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/session/admin/v2/loginrequest",
        method: "POST",
        body: {
          username: credentials?.username,
          password: credentials?.password,
          // language: "en",
          platform: "web",
        },
      }),
    }),
    passwordReset: builder.mutation({
      query: (credentials) => ({
        url: "/session/v1/passreset",
        method: "POST",
        body: {
          username: credentials?.username,
        },
      }),
    }),
    changingPassword: builder.mutation({
      query: (credentials) => ({
        url: "/session/v1/passresetconfirm",
        method: "POST",
        body: {
          passCode: credentials?.code,
          requestId: credentials?.requestId,
          password: credentials?.password,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  usePasswordResetMutation,
  useChangingPasswordMutation,
} = authApiSlice;
